// extracted by mini-css-extract-plugin
export var hideForMobile = "Modal-module--hide-for-mobile--KXKr0";
export var hideForTablet = "Modal-module--hide-for-tablet--u-FHH";
export var hideForLaptop = "Modal-module--hide-for-laptop--+gi+F";
export var hideForDesktop = "Modal-module--hide-for-desktop--8yeFj";
export var hideForExtraLarge = "Modal-module--hide-for-extra-large--IEVgH";
export var showForMobileOnly = "Modal-module--show-for-mobile-only--w1BIg";
export var showForTabletOnly = "Modal-module--show-for-tablet-only--iQXhM";
export var showForLaptopOnly = "Modal-module--show-for-laptop-only--ITwFe";
export var showForDesktopOnly = "Modal-module--show-for-desktop-only--BO6rU";
export var showForExtraLargeOnly = "Modal-module--show-for-extra-large-only--o41DV";
export var fullPageContainer = "Modal-module--full-page-container--PPw0o";
export var modal = "Modal-module--modal--71CJh";
export var bodyScrolled = "Modal-module--bodyScrolled--U9bjh";
export var modalBody = "Modal-module--modalBody--1a8u0";
export var overlayReveal = "Modal-module--overlayReveal--wGnnB";