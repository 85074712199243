// extracted by mini-css-extract-plugin
export var hideForMobile = "ArrowButton-module--hide-for-mobile--gdjos";
export var hideForTablet = "ArrowButton-module--hide-for-tablet--DhGxB";
export var hideForLaptop = "ArrowButton-module--hide-for-laptop--R0wzZ";
export var hideForDesktop = "ArrowButton-module--hide-for-desktop--NjMrh";
export var hideForExtraLarge = "ArrowButton-module--hide-for-extra-large--hlhRt";
export var showForMobileOnly = "ArrowButton-module--show-for-mobile-only--vdRLx";
export var showForTabletOnly = "ArrowButton-module--show-for-tablet-only--UGDPO";
export var showForLaptopOnly = "ArrowButton-module--show-for-laptop-only--nt-Mw";
export var showForDesktopOnly = "ArrowButton-module--show-for-desktop-only--Q9pOP";
export var showForExtraLargeOnly = "ArrowButton-module--show-for-extra-large-only--6mJJs";
export var fullPageContainer = "ArrowButton-module--full-page-container--FrHu9";
export var container = "ArrowButton-module--container--V+aa0";
export var left = "ArrowButton-module--left--wI3J-";
export var right = "ArrowButton-module--right--s1JT5";
export var bounceRight = "ArrowButton-module--bounceRight--K4rp1";
export var bounceLeft = "ArrowButton-module--bounceLeft--tN9AV";
export var arrow = "ArrowButton-module--arrow--3-uTV";