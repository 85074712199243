import React, { useState, useEffect, useContext } from "react"
import ReactDOM from "react-dom"
import classNames from "classnames"
import * as styles from "./Modal.module.scss"

const portalRoot =
  typeof document !== `undefined`
    ? document.getElementById("portal-root")
    : null

export const ModalContext = React.createContext({
  open: false,
  handleOpen: () => null,
  bodyScrolled: false,
})

const Modal = ({ isOpen, children }) => {
  const { bodyScrolled } = useContext(ModalContext)
  const el =
    typeof document !== `undefined` ? document.createElement("div") : null

  useEffect(() => {
    portalRoot.appendChild(el)
    return () => {
      portalRoot.removeChild(el)
    }
  }, [el])

  if (!el || !isOpen) return null

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.modal, {
        [styles.bodyScrolled]: bodyScrolled,
      })}
    >
      {children}
    </div>,
    portalRoot
  )
}

let closeFunction = () => null

export const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [bodyScrolled, setBodyScrolled] = useState(false)

  const handleOpen = openState => {
    setOpen(openState)
  }

  const handleClose = () => closeFunction()
  const handleCloseFn = closeFn => (closeFunction = closeFn)
  const handleBodyScrolled = (bool) => {
    setBodyScrolled(bool)
  }

  return (
    <ModalContext.Provider
      value={{
        open,
        bodyScrolled,
        handleBodyScrolled,
        handleOpen,
        handleCloseFn,
        handleClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default Modal
