import React from "react"
import { motion } from "framer-motion"

const duration = 0.3

const variants = {
  initial: {
    opacity: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: 0.2,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, when: "beforeChildren" },
  },
}

const FadeIn = ({ children }) => {
  return (
    <motion.main
      variants={variants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      {children}
    </motion.main>
  )
}

export default FadeIn

