import React, { useState } from "react"
import classNames from "classnames"
import { Link, useStaticQuery, graphql } from "gatsby"
import * as styles from "./footer.module.scss"
import HSFlag from "../HSFlag"
import { Formik } from "formik"
import * as Yup from "yup"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

 const SignupSchema = Yup.object().shape({
   email: Yup.string()
     .email("Please submit a valid email address")
     .required("Please submit a valid email address")
 })

const Footer = () => {
  const [submitSuccess, handlePostSubmit] = useState(false)
  const {
    contentfulSettings: {
      socialMediaLinks,
      supportEmailAddress,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulSettings {
        supportEmailAddress
        socialMediaLinks {
          id
          url
          icon {
            file {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <HSFlag />
          </div>
          <div className={styles.tip}>Have an idea?</div>
          <div>
            <Link to="/contact">
              <div className={styles.contactButton}>Get In Touch</div>
            </Link>
          </div>
        </div>
        <div className={styles.socialInfo}>
          <div>
            {!submitSuccess && (
              <>
                <div className={styles.emailCaptureHeader}>
                  Want to hear from us?
                </div>

                <Formik
                  validateOnBlur={true}
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={values => {
                    fetch("/", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                      body: encode({ "form-name": "newsletter", ...values }),
                    })
                      .then(() => {
                        handlePostSubmit(true)
                        window.gtag("event", "Newsletter Form Submit")
                      })
                      .catch(error => {
                        handlePostSubmit(false)
                        console.log("ERROR")
                      })
                  }}
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    submitCount,
                  }) => (
                    <>
                      <form
                        name="newsletter"
                        method="POST"
                        data-netlify="true"
                        onSubmit={handleSubmit}
                        className={classNames(styles.emailCaptureForm, {
                          [styles.error]: submitCount > 0 && errors.email,
                        })}
                      >
                        <input
                          type="hidden"
                          name="form-name"
                          value="newsletter"
                        />
                        <input
                          type="text"
                          name="email"
                          placeholder="Join our newsletter"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={styles.emailCaptureInput}
                        />
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={styles.emailCaptureSubmit}
                        >
                          {!submitSuccess && !isSubmitting && (
                            <svg
                              width="24"
                              height="16"
                              viewBox="0 0 24 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 8L23 8"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16 1L23 8L16 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                          {!submitSuccess && isSubmitting && (
                            <div className={styles.ldsEllipsis}>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                        </button>
                      </form>
                      <div className={styles.emailCaptureError}>
                        {submitCount > 0 && errors.email && (
                          <div>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.85953 2.57335L1.21286 12C1.09644 12.2016 1.03484 12.4302 1.03418 12.663C1.03353 12.8958 1.09385 13.1248 1.20914 13.327C1.32443 13.5293 1.49068 13.6978 1.69133 13.8159C1.89199 13.934 2.12006 13.9975 2.35286 14H13.6462C13.879 13.9975 14.1071 13.934 14.3077 13.8159C14.5084 13.6978 14.6746 13.5293 14.7899 13.327C14.9052 13.1248 14.9655 12.8958 14.9649 12.663C14.9642 12.4302 14.9026 12.2016 14.7862 12L9.13953 2.57335C9.02068 2.37742 8.85334 2.21543 8.65366 2.103C8.45397 1.99058 8.22868 1.93152 7.99953 1.93152C7.77037 1.93152 7.54508 1.99058 7.3454 2.103C7.14571 2.21543 6.97837 2.37742 6.85953 2.57335V2.57335Z"
                                fill="#FF0000"
                              />
                              <path
                                d="M8 6V8.66667"
                                stroke="white"
                                stroke-width="1.33333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8 11.3334H8.00625"
                                stroke="white"
                                stroke-width="1.33333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Formik>
              </>
            )}
            {submitSuccess && (
              <div className={styles.emailSuccessMessage}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5L8 16L3 11"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Thanks for your submission!
              </div>
            )}
          </div>
          <div className={styles.links}>
            {socialMediaLinks.map(({ id, url, icon }) => {
              return (
                <a
                  key={id}
                  href={url}
                  className={styles.socialLogo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={icon.file.url} />
                </a>
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles.emailContainer}>
        <a href={`mailto:${supportEmailAddress}`}>
          👋{" "}
          <span className={styles.emailUnderline}>{supportEmailAddress}</span>
        </a>
      </div>
    </>
  )
}

export default Footer
