import React, { useState, useEffect } from "react"
import { sortBy } from "lodash"
import useDimensions from "../../hooks/useDimensions"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./responsiveImage.module.scss"
import { motion } from "framer-motion"
import { BgImage } from "gbimage-bridge"

const ResponsiveImage = ({
  images,
  respondToWidth,
  children,
  imgStyle,
  animated,
}) => {
  if (respondToWidth && images.length > 1) {
    return (
      <RespondToScreenImage images={images} children={children} />
    )
  }

  return (
    <RespondToContainerImage
      images={images}
      respondToWidth={respondToWidth}
      children={children}
      animated={animated}
      imgStyle={imgStyle}
    />
  )
}

const RespondToScreenImage = ({ images, children }) => {
  const sortedImagesByWidth = sortBy(
    images,
    gatsbyImage => gatsbyImage.file.details.image.width
  )

  
  const imagesLength = sortedImagesByWidth.length
  const hideBreakpoints = [
    "hide-for-mobile",
    "hide-for-tablet",
    "hide-for-laptop",
    "hide-for-desktop",
    "hide-for-extra-large",
  ]
  
  return sortedImagesByWidth.map((gatsbyImage, index) => {
    let imageBreakpointClasses = []
    if (index === imagesLength - 1) {
      imageBreakpointClasses = [...hideBreakpoints.slice(0, index)]
    } else {
      imageBreakpointClasses = hideBreakpoints.filter((_breakpoint, i) =>
        i !== index ? true : false
      )
    }

    return (
      <div
        className={[styles.imageContainer, ...imageBreakpointClasses].join(" ")}
        style={{ width: "100%", height: "100%" }}
      >
        <GatsbyImage
          image={gatsbyImage.gatsbyImageData}
          alt={gatsbyImage.title || ''}
          loading="eager"
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />
        {children && (
          <div className={styles.textOverlayContainer}>
            <div
              className={styles.textOverlay}
              dangerouslySetInnerHTML={{
                __html: children.childMarkdownRemark.html,
              }}
            />
          </div>
        )}
      </div>
    )
  })
}

const RespondToContainerImage = ({
  images,
  respondToWidth,
  children,
  imgStyle,
  animated,
}) => {
  const [ref, { width, height }] = useDimensions()
  const [image, setImage] = useState(images[0])

  useEffect(() => {
    let closestMatch
    if (respondToWidth) {
      const containerWidth = width * 2
      closestMatch = images.reduce(function (prev, curr) {
        return Math.abs(curr.file.details.image.width - containerWidth) <
          Math.abs(prev.file.details.image.width - containerWidth)
          ? curr
          : prev
      })
    } else {
      const containerAspectRatio = width / height
      closestMatch = images.reduce(function (prev, curr) {
        const currentAspectRatio = curr.file.details.image.width / curr.file.details.image.height
        const previousAspectRatio = prev.file.details.image.width / prev.file.details.image.height
        return Math.abs(currentAspectRatio - containerAspectRatio) <
          Math.abs(previousAspectRatio - containerAspectRatio)
          ? curr
          : prev
      })
    }

    setImage(closestMatch)
  }, [height, width])

  if (animated) {
    return (
      <div
        ref={ref}
        className={styles.imageContainer}
        style={{ width: "100%", height: "100%" }}
      >
        <motion.div
          className={styles.imageWrapper}
          style={{
            ...imgStyle,
          }}
        >
          <BgImage
            image={image.gatsbyImageData}
            alt={image.title || ''}
            loading="eager"
            style={{
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              backgroundAttachment: "fixed",
              backgroundPosition: "center",
              backgroundRepeat: "noRepeat",
            }}
          >
            {children && (
              <div className={styles.textOverlayContainer}>
                <div
                  className={styles.textOverlay}
                  dangerouslySetInnerHTML={{
                    __html: children.childMarkdownRemark.html,
                  }}
                />
              </div>
            )}
          </BgImage>
        </motion.div>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      className={styles.imageContainer}
      style={{ width: "100%", height: "100%" }}
    >
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.title || ''}
        loading="eager"
        style={{
          objectFit: "cover",
          height: "100%",
          width: "100%",
        }}
      />
      {children && (
        <div className={styles.textOverlayContainer}>
          <div
            className={styles.textOverlay}
            dangerouslySetInnerHTML={{
              __html: children.childMarkdownRemark.html,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ResponsiveImage