import React from "react"
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './ArrowButton.module.scss'

const ArrowButton = ({ direction, ...props }) => {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.container, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right'
      })}
      {...props}
    >
      <g
      className={styles.arrow}>
        <path
          d="M22.32 32.68L17.2299 27.5898L22.32 22.4997"
          stroke="currentColor"
          strokeWidth="2"
        />
        <line
          x1="38.5"
          y1="27.5"
          x2="17.5"
          y2="27.5"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <rect
        x="54"
        y="1"
        width="52"
        height="53"
        rx="26"
        transform="rotate(90 54 1)"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}

ArrowButton.propTypes = {
  direction: PropTypes.oneOf(['left', 'right'])
}

ArrowButton.defaultProps = {
  direction: 'left'
}

export default ArrowButton
