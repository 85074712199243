// extracted by mini-css-extract-plugin
export var hideForMobile = "button-module--hide-for-mobile--BBuGw";
export var hideForTablet = "button-module--hide-for-tablet--H0kQk";
export var hideForLaptop = "button-module--hide-for-laptop--SoVmM";
export var hideForDesktop = "button-module--hide-for-desktop--JlQ6k";
export var hideForExtraLarge = "button-module--hide-for-extra-large--6yqc7";
export var showForMobileOnly = "button-module--show-for-mobile-only--Nf1Gt";
export var showForTabletOnly = "button-module--show-for-tablet-only--8i15m";
export var showForLaptopOnly = "button-module--show-for-laptop-only--Zi+f6";
export var showForDesktopOnly = "button-module--show-for-desktop-only--OJc9n";
export var showForExtraLargeOnly = "button-module--show-for-extra-large-only--clW71";
export var fullPageContainer = "button-module--full-page-container--Vj0uE";
export var button = "button-module--button--X-qXA";
export var ghost = "button-module--ghost--+88A3";
export var circleButton = "button-module--circleButton--Bf03O";
export var selected = "button-module--selected--vksYq";