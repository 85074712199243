import React, { useRef, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import * as styles from "./menu.module.scss"
import HSLogo from "../HSLogo"
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import ResponsiveImage from "../responsiveImage"
import Button from "../button"
import Slider from "react-slick"
import ArrowButton from "../Animated/ArrowButton"

const Menu = ({ handleMenu, open, pathname }) => {
  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [textFade, setTextFade] = useState(false)
  const [slidesInitialized, setInitialize] = useState(false)

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
    speed: 900,
    cssEase: "cubic-bezier(.165,.84,.44, 1)",
    pauseOnHover: true,
    pauseOnFocus: true,
    beforeChange: () => {
      setTextFade(true)
      if (!slidesInitialized) {
        setInitialize(true)
      }
    },
    afterChange: currentSlideIndex => {
      setCurrentSlide(currentSlideIndex)
      setTextFade(false)
    },
  }

  const data = useStaticQuery(graphql`
    query MenuQuery {
      allContentfulSettings {
        edges {
          node {
            supportEmailAddress
          }
        }
      }
      contentfulCaseStudiesList(name: { eq: "Navigation Case Studies" }) {
        caseStudies {
          id
          name
          client {
            id
            name
          }
          textColor
          isLive
          slug
          noWrap
          featuredImages {
            images {
              title
              gatsbyImageData(layout: FULL_WIDTH, quality: 70)
              file {
                details {
                  size
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const handleNextSlide = () => {
    slider.current.slickNext()
  }

  const handlePrevSlide = () => {
    slider.current.slickPrev()
  }

  return (
    <motion.div
      variants={offCanvasMenuAnimationVariants}
      initial="hidden"
      animate={open ? "visible" : "hidden"}
      className={styles.container}
    >
      <motion.div
        className={styles.menuOverlayContainer}
        initial="hidden"
        variants={offCanvasMenuOverlayAnimationVariants}
      ></motion.div>
      <motion.div
        className={styles.caseStudyContainer}
        initial="hidden"
        variants={caseStudySliderAnimationVariants}
      >
        <Slider {...settings} style={{ height: "100%" }} ref={slider}>
          {data.contentfulCaseStudiesList.caseStudies.map(
            ({ featuredImages, id, textColor }, index) => {
              return (
                <SlideCaseStudy
                  currentSlide={currentSlide}
                  id={id}
                  index={index}
                  key={`case-study-${id}`}
                  textColor={textColor}
                  featuredImages={featuredImages}
                />
              )
            }
          )}
        </Slider>
        <motion.div
          initial="hidden"
          variants={navLinkListAnimationVariants}
          className={classNames(styles.clientContainer, {
            [styles.fade]: textFade,
          })}
        >
          <motion.div
            initial="hidden"
            variants={navLinkItemAnimationVariants}
            className={classNames(styles.carouselArrows, {
              [styles.fade]: textFade,
              [styles.dark]:
                data.contentfulCaseStudiesList.caseStudies[currentSlide]
                  .textColor,
            })}
          >
            <ArrowButton
              style={{ marginRight: "23px" }}
              onClick={handlePrevSlide}
            />
            <ArrowButton direction="right" onClick={handleNextSlide} />
          </motion.div>

          <Link
            initial="hidden"
            to={`/case-studies/${data.contentfulCaseStudiesList.caseStudies[currentSlide].slug}`}
            onClick={handleMenu}
          >
            <motion.div
              initial="hidden"
              animate="visible"
              key={`nav-case-study-client-${currentSlide}`}
              variants={
                slidesInitialized
                  ? slideAnimationVariant
                  : navLinkItemAnimationVariants
              }
              className={classNames(styles.clientName, {
                [styles.dark]:
                  data.contentfulCaseStudiesList.caseStudies[currentSlide]
                    .textColor,
              })}
            >
              {
                data.contentfulCaseStudiesList.caseStudies[currentSlide]
                  .client[0].name
              }
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={
                slidesInitialized
                  ? slideAnimationVariant
                  : navLinkItemAnimationVariants
              }
              key={`nav-case-study-title-${currentSlide}`}
              className={classNames(styles.caseName, {
                [styles.dark]:
                  data.contentfulCaseStudiesList.caseStudies[currentSlide]
                    .textColor,
              })}
            >
              {data.contentfulCaseStudiesList.caseStudies[currentSlide].name}
            </motion.div>
          </Link>
        </motion.div>
      </motion.div>

      <div className={styles.menu}>
        <motion.div
          className={styles.navOverlay}
          initial="hidden"
          variants={navLinksOverlayAnimationVariants}
        />
        <div className={styles.header}>
          <motion.div
            initial="hidden"
            variants={{
              hidden: {
                y: "-100px",
                transition: {
                  duration: 0.3,
                  delay: 0.2,
                },
              },
              visible: {
                y: "0",
                transition: {
                  duration: 0.3,
                  delay: 0.2,
                },
              },
            }}
          >
            <Link to="/" onClick={handleMenu}>
              <HSLogo style={{ marginTop: 0 }} />
            </Link>
          </motion.div>
          <motion.div
            className={styles.closeButtonContainer}
            initial="hidden"
            variants={{
              hidden: {
                height: 0,
                transition: {
                  duration: 0.3,
                },
              },
              visible: {
                height: "auto",
                transition: {
                  delay: 0.3,
                  duration: 0.4,
                },
              },
            }}
          >
            <Button onClick={handleMenu}>CLOSE</Button>
          </motion.div>
        </div>

        <motion.nav
          initial="hidden"
          variants={navLinkListAnimationVariants}
          className={styles.nav}
        >
          {navLinks.map((link, index) => {
            if (link.type === "external") {
              return (
                <motion.div
                  key={`menu-item-${index}`}
                  initial="hidden"
                  className={[styles.navLink, "underline-1"].join(" ")}
                  onClick={handleMenu}
                  variants={navLinkItemAnimationVariants}
                >
                  <a target="_blank" href={link.url}>
                    {link.name}
                  </a>
                </motion.div>
              )
            } else if (pathname === link.url) {
              return (
                <motion.div
                  key={`menu-item-${index}`}
                  initial="hidden"
                  variants={navLinkItemAnimationVariants}
                  onClick={handleMenu}
                  className={[styles.navLink, styles.active].join(" ")}
                >
                  {link.name}
                </motion.div>
              )
            } else {
              return (
                <motion.div
                  key={`menu-item-${index}`}
                  initial="hidden"
                  className={[styles.navLink, "underline-1"].join(" ")}
                  onClick={handleMenu}
                  variants={navLinkItemAnimationVariants}
                >
                  <Link to={link.url}>{link.name}</Link>
                </motion.div>
              )
            }
          })}
        </motion.nav>

        <motion.div
          className={styles.footer}
          initial="hidden"
          variants={navLinkFooterListAnimationVariants}
        >
          <motion.div
            initial="hidden"
            variants={navLinkItemAnimationVariants}
            className={styles.subheader}
          >
            Start a conversation
          </motion.div>
          <motion.div initial="hidden" variants={navLinkItemAnimationVariants}>
            <a
              className={[styles.email, "underline-2"].join(" ")}
              href={`mailto:${data.allContentfulSettings.edges[0].node.supportEmailAddress}`}
            >
              {data.allContentfulSettings.edges[0].node.supportEmailAddress}
            </a>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}

const SlideCaseStudy = ({
  id,
  textColor,
  featuredImages,
  currentSlide,
  index,
}) => {
  return (
    <div
      key={`nav-case-study-${id}`}
      className={classNames(styles.caseStudySlide, {
        [styles.dark]: textColor,
        [styles.active]: index === currentSlide,
      })}
    >
      <ResponsiveImage
        images={featuredImages[0].images}
        alt="navigation-case-study"
      />
    </div>
  )
}

Menu.propTypes = {
  handleMenu: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

const navLinks = [
  { type: "internal", name: "Work", url: "/work" },
  { type: "internal", name: "Us", url: "/about/" },
  {
    type: "external",
    name: "Careers",
    url: "https://apply.workable.com/herman-scheer/",
  },
  // { type: "internal", name: "Services", url: "/services/" },
  { type: "internal", name: "The Juice", url: "/blog/" },
  { type: "internal", name: "Contact", url: "/contact/" },
]

const offCanvasMenuAnimationVariants = {
  visible: {
    pointerEvents: "all",
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: 0,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    pointerEvents: "none",
    opacity: 0,
    transition: {
      when: "afterChildren",
      duration: 0,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const offCanvasMenuOverlayAnimationVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 1.5,
      delay: 0.4,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const caseStudySliderAnimationVariants = {
  visible: {
    y: "0",
    transition: {
      duration: 1.2,
      delay: 0.2,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    y: "-100%",
    transition: {
      duration: 1.2,
      delay: 0.7,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const navLinksOverlayAnimationVariants = {
  visible: {
    y: "0",
    transition: {
      duration: 1.2,
      delay: 0,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    y: "-100%",
    transition: {
      duration: 1.2,
      delay: 0.5,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const navLinkListAnimationVariants = {
  visible: {
    y: "0",
    transition: {
      staggerChildren: 0.05,
      when: "beforeChildren",
      delay: 0.2,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    y: "1",
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: "beforeChildren",
      delay: 2 * 0.05,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const navLinkFooterListAnimationVariants = {
  visible: {
    y: "0",
    transition: {
      staggerChildren: 0.05,
      when: "beforeChildren",
      delay: 0.2 + 6 * 0.05,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    y: "1",
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const navLinkItemAnimationVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    opacity: 0,
    y: 80,
    transition: {
      duration: 0.5,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const slideAnimationVariant = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  hidden: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.2,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

export default Menu
