import React, { useState } from "react"
import * as styles from "./HSFlag.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import LottieComponent from "../CaseStudyBlocks/LottieComponent"

const Header = ({width, style}) => {
  const [play, setPlay] = useState(false)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulLottieComponent(name: { eq: "HS Flag Black" }) {
        id
        name
        jsonFile {
          file {
            url
          }
        }
        loop
        renderer
        autoplay
      }
    }
  `)

  function handleEnter(){
    setPlay(true);
  }
  function handleLeave(){
    setPlay(false);
  }

  return (
    <div
      className={styles.logo}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      style={{
        width: width,
        ...style
      }}
    >
      <LottieComponent
        component={{ ...data.contentfulLottieComponent, play: play }}
      />
    </div>
  )
}

export default Header
