// extracted by mini-css-extract-plugin
export var hideForMobile = "responsiveImage-module--hide-for-mobile--rrOiG";
export var hideForTablet = "responsiveImage-module--hide-for-tablet--+4FMH";
export var hideForLaptop = "responsiveImage-module--hide-for-laptop--7LgxL";
export var hideForDesktop = "responsiveImage-module--hide-for-desktop--TdfTZ";
export var hideForExtraLarge = "responsiveImage-module--hide-for-extra-large--8oyY3";
export var showForMobileOnly = "responsiveImage-module--show-for-mobile-only--CD-0T";
export var showForTabletOnly = "responsiveImage-module--show-for-tablet-only--t4GcG";
export var showForLaptopOnly = "responsiveImage-module--show-for-laptop-only--XG6cf";
export var showForDesktopOnly = "responsiveImage-module--show-for-desktop-only--rKr2O";
export var showForExtraLargeOnly = "responsiveImage-module--show-for-extra-large-only--VLAbo";
export var fullPageContainer = "responsiveImage-module--full-page-container--FN1T6";
export var imageContainer = "responsiveImage-module--imageContainer--5sr7y";
export var imageWrapper = "responsiveImage-module--imageWrapper--SFx7+";
export var textOverlayContainer = "responsiveImage-module--textOverlayContainer--BCtae";
export var textOverlay = "responsiveImage-module--textOverlay--uowdx";