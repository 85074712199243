import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./LoadingScreen.module.scss"
import LottieComponent from "../CaseStudyBlocks/LottieComponent"

const duration = 0.3

const overlayVariants = {
  initial: {
    opacity: 1,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { delay: 0.2, duration: 1 },
  },
}

const flagVariants = {
  initial: {
    opacity: 0,
    y: 100
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.2,
      ease: [.165,.84,.44,1],
      delay: 0
    },
  },
  exit: {
    opacity: 0,
    y: -100,
    transition: { 
      duration: 1, 
      ease: [.165,.84,.44,1]
    },
  },
}

const LoadingScreen = ({ loading }) => {
  const data = useStaticQuery(graphql`
    query LoadingScreenQuery {
      contentfulLottieComponent(name: { eq: "HS Flag Black" }) {
        id
        name
        jsonFile {
          file {
            url
          }
        }
        loop
        renderer
        autoplay
      }
    }
  `)
  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          className={styles.container}
          variants={overlayVariants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <motion.div
            className={styles.flagContainer}
            variants={flagVariants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <LottieComponent
              component={{
                ...data.contentfulLottieComponent,
                play: true,
                autoplay: true,
              }}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LoadingScreen
