import React, { useState, useEffect, createRef } from "react"

import lottie from "lottie-web"

const LottieComponent = ({
  component: { jsonFile, autoplay, loop, renderer, play },
}) => {
  let animationContainer = createRef()
  const [anim, setAnimation] = useState()

  useEffect(() => {
    setAnimation(
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: renderer,
        loop: loop,
        autoplay: autoplay,
        path: jsonFile.file.url,
      })
    )
  }, [])

  useEffect(() => {
    if (anim) {
      if (play) {
        anim.play()
      } else {
        anim.onLoopComplete = () => anim.pause();
      }
    }
  }, [play])

  return (
    <div style={{ display: "flex", width: "100%" }} ref={animationContainer} />
  )
}

export default LottieComponent
