import React from 'react';
import PropTypes from 'prop-types'
import cn from 'classnames'
import * as styles from './button.module.scss'

const Button = ({ ghost, children, onClick }) => {
  const buttonStyle = cn(styles.button, {
    [styles.ghost]: ghost
  })

  return (
    <button className={buttonStyle} onClick={onClick}>{children}</button>
  )
}

export const CircleButton = ({ children, selected, onClick }) => {
  const buttonStyle = cn(styles.circleButton, {
    [styles.selected]: selected
  })

  return (
    <button className={buttonStyle} onClick={onClick}>{children}</button>
  )
}

Button.propTypes = {
  ghost: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

Button.defaultProps = {
  ghost: false
}

CircleButton.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

CircleButton.defaultProps = {
  selected: false
}

export default Button