// extracted by mini-css-extract-plugin
export var hideForMobile = "HSLogo-module--hide-for-mobile--oFW4W";
export var hideForTablet = "HSLogo-module--hide-for-tablet--LHcbB";
export var hideForLaptop = "HSLogo-module--hide-for-laptop--i1Ozt";
export var hideForDesktop = "HSLogo-module--hide-for-desktop--BUqsl";
export var hideForExtraLarge = "HSLogo-module--hide-for-extra-large--s5hcz";
export var showForMobileOnly = "HSLogo-module--show-for-mobile-only--r07G6";
export var showForTabletOnly = "HSLogo-module--show-for-tablet-only--4N2lZ";
export var showForLaptopOnly = "HSLogo-module--show-for-laptop-only--T5gDc";
export var showForDesktopOnly = "HSLogo-module--show-for-desktop-only--DlQcE";
export var showForExtraLargeOnly = "HSLogo-module--show-for-extra-large-only--BhLdv";
export var fullPageContainer = "HSLogo-module--full-page-container--ASDhN";
export var logo = "HSLogo-module--logo--lISEB";