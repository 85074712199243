// extracted by mini-css-extract-plugin
export var hideForMobile = "footer-module--hide-for-mobile--XSkls";
export var hideForTablet = "footer-module--hide-for-tablet--Q+txL";
export var hideForLaptop = "footer-module--hide-for-laptop--mrnEX";
export var hideForDesktop = "footer-module--hide-for-desktop--mK-RQ";
export var hideForExtraLarge = "footer-module--hide-for-extra-large--rC5fB";
export var showForMobileOnly = "footer-module--show-for-mobile-only--kV0GK";
export var showForTabletOnly = "footer-module--show-for-tablet-only--pC6eX";
export var showForLaptopOnly = "footer-module--show-for-laptop-only--FfSZ8";
export var showForDesktopOnly = "footer-module--show-for-desktop-only--KfvDX";
export var showForExtraLargeOnly = "footer-module--show-for-extra-large-only--4uohP";
export var fullPageContainer = "footer-module--full-page-container--HAMux";
export var container = "footer-module--container--wjHmS";
export var content = "footer-module--content--kHUvg";
export var logoContainer = "footer-module--logoContainer--EeXOF";
export var header = "footer-module--header--d1xC+";
export var tip = "footer-module--tip--7mqa9";
export var socialInfo = "footer-module--socialInfo--G+7NK";
export var copyright = "footer-module--copyright--Bg9Rz";
export var links = "footer-module--links--83gnD";
export var socialLogo = "footer-module--socialLogo--QV5PP";
export var gradient = "footer-module--gradient--AoSK6";
export var emailContainer = "footer-module--emailContainer--sqXZL";
export var emailUnderline = "footer-module--emailUnderline--Rbq4A";
export var contactButton = "footer-module--contactButton--IFNyd";
export var emailCaptureHeader = "footer-module--emailCaptureHeader--o8YVk";
export var emailSuccessMessage = "footer-module--emailSuccessMessage--dNXlt";
export var emailCaptureForm = "footer-module--emailCaptureForm--aBx12";
export var error = "footer-module--error--UFB2o";
export var emailCaptureInput = "footer-module--emailCaptureInput--f1oAA";
export var emailCaptureSubmit = "footer-module--emailCaptureSubmit--GwN7l";
export var emailCaptureError = "footer-module--emailCaptureError--3GSU3";
export var ldsEllipsis = "footer-module--ldsEllipsis--aS92P";
export var ldsEllipsis1 = "footer-module--lds-ellipsis1--29u2g";
export var ldsEllipsis2 = "footer-module--lds-ellipsis2--l8a7e";
export var ldsEllipsis3 = "footer-module--lds-ellipsis3--ggkVF";