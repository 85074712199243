import React from "react"
import * as styles from "./HSLogo.module.scss"

const HSLogo = ({width, style}) => {

  return (
    <div
      className={styles.logo}
      style={{
        width: width,
        ...style,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.87in"
        height="4.43in"
        viewBox="0 0 710.62 318.8"
        preserveAspectRatio="xMinYMin meet"
      >
        <path d="M93.12,132.93V77.81H35.34v55.12H9.54V0h25.8V53.43H93.12V0h26V132.93Z" />
        <path d="M223.54,106.49c-4.48,16.13-19.45,29.25-41.88,29.25-25.43,0-47.5-18.19-47.5-49.31,0-29.43,21.69-48.56,45.25-48.56,28.61,0,45.44,18,45.44,47.81,0,3.56-.37,7.13-.37,7.69H158.66c.56,12,10.84,21,23.19,21,11.77,0,18-5.82,20.94-14.07ZM200.36,76.31c-.56-9-6.36-18.19-20.57-18.19-12.9,0-20,9.94-20.57,18.19Z" />
        <path d="M298.41,65.43a33.91,33.91,0,0,0-7.48-.75c-12.71,0-23.75,6.19-23.75,26.07v42.18H242.32V40.68h24.12V54.37c5.61-12.18,18.32-14.44,26.17-14.44,2.06,0,3.74.19,5.8.38Z" />
        <path d="M314.23,132.93V40.68H338V51.93c5-9,16.83-13.87,26.92-13.87,12.72,0,22.63,5.44,27.3,15.37,7.29-11.24,17-15.37,29.17-15.37,17,0,33.28,10.31,33.28,35.06v59.81H430.53V78.18c0-9.94-4.86-17.43-16.26-17.43-10.66,0-17,8.25-17,18.18v54H372.57V78.18c0-9.94-5.05-17.43-16.27-17.43-11,0-17.2,7.87-17.2,18.18v54Z" />
        <path d="M499.51,79.87l22.62-3.38c5.24-.75,6.92-3.37,6.92-6.56,0-6.56-5-12-15.52-12-11,0-16.82,6.94-17.57,15l-22.07-4.69c1.5-14.43,14.59-30.37,39.46-30.37,29.17,0,40,16.5,40,35.06V118.3a101.6,101.6,0,0,0,1.12,14.63H531.67a62.79,62.79,0,0,1-.93-11.06c-4.68,7.31-13.47,13.68-27.12,13.68-19.63,0-31.6-13.5-31.6-27.75C472,91.12,484.18,82.31,499.51,79.87Zm29.54,15.75V91.49L508.3,94.68c-6.54.94-11.41,4.31-11.41,11.63,0,5.43,3.93,10.68,12,10.68C519.33,117,529.05,111.93,529.05,95.62Z" />
        <path d="M596.22,132.93H571.35V40.68h24.12V52.12c5.8-9.56,16.64-13.87,26.55-13.87,23,0,33.28,16.31,33.28,36.56v58.12H630.43V79.12c0-10.5-5-18.37-17-18.37-11,0-17.2,8.43-17.2,19.12Z" />
        <path d="M693.41,100.12a17.06,17.06,0,1,1-17,17.06A16.91,16.91,0,0,1,693.41,100.12Z" />
        <path d="M75.54,221.88c-1.5-7.51-6.92-18.75-24.12-18.75-12.53,0-20.76,8.06-20.76,16.87,0,7.31,4.68,12.94,14.4,15l18.51,3.56c23.94,4.5,37,20.62,37,39.19,0,20.43-17.2,41.05-47.86,41.05C17.57,318.8,2.06,296.12,0,277.18l23.93-6.37c.94,12.93,10.29,24.93,29,24.93,14,0,21.51-6.74,21.51-16.31,0-7.68-5.61-13.68-16.08-15.75l-18.51-3.75C18.7,255.62,5.05,242.12,5.05,221.88c0-23.44,21.13-41.63,46.18-41.63,32,0,44.51,19.5,47.5,34.5Z" />
        <path d="M137.6,269.87c0,16.68,10.84,26.06,23.56,26.06s19.07-8.44,21.13-15.56l21.88,7.31c-3.93,15.37-18.7,31.12-43,31.12-27.11,0-48.43-20.62-48.43-48.93,0-28.5,20.76-48.93,47.68-48.93,25.06,0,39.27,15.56,43.19,31.12l-22.25,7.5C179.11,251.87,173.12,244,161,244,148.26,244,137.6,253.19,137.6,269.87Z" />
        <path d="M244.91,316H220V180.25h24.87v51.19c6-7.32,16.08-10.13,24.87-10.13,23.37,0,34.21,16.32,34.21,36.56V316H279.12V262.18c0-10.5-5.05-18.37-17-18.37-10.47,0-16.64,7.69-17.2,18Z" />
        <path d="M408.05,289.56c-4.48,16.12-19.44,29.24-41.88,29.24-25.43,0-47.5-18.18-47.5-49.3,0-29.44,21.69-48.56,45.26-48.56,28.6,0,45.43,18,45.43,47.81,0,3.56-.37,7.12-.37,7.68H343.17c.56,12,10.84,21,23.19,21,11.78,0,17.95-5.81,20.94-14.06Zm-23.18-30.19c-.56-9-6.36-18.18-20.57-18.18-12.9,0-20,9.93-20.57,18.18Z" />
        <path d="M510.39,289.56c-4.49,16.12-19.45,29.24-41.88,29.24-25.44,0-47.5-18.18-47.5-49.3,0-29.44,21.69-48.56,45.25-48.56,28.61,0,45.44,18,45.44,47.81,0,3.56-.38,7.12-.38,7.68H445.51c.56,12,10.84,21,23.18,21,11.78,0,17.95-5.81,20.95-14.06ZM487.2,259.37c-.56-9-6.35-18.18-20.56-18.18-12.91,0-20,9.93-20.57,18.18Z" />
        <path d="M585.26,248.5a33.32,33.32,0,0,0-7.48-.75c-12.72,0-23.75,6.19-23.75,26.06V316H529.16V223.75h24.12v13.69c5.61-12.19,18.33-14.44,26.18-14.44,2.06,0,3.74.19,5.8.37Z" />
        <path d="M602.23,283.18a17.07,17.07,0,1,1-17,17.06A16.91,16.91,0,0,1,602.23,283.18Z" />
      </svg>
    </div>
  )
}

export default HSLogo
