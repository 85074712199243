// extracted by mini-css-extract-plugin
export var hideForMobile = "header-module--hide-for-mobile--m-qDJ";
export var hideForTablet = "header-module--hide-for-tablet--o--n6";
export var hideForLaptop = "header-module--hide-for-laptop--VNNuO";
export var hideForDesktop = "header-module--hide-for-desktop--8SNDb";
export var hideForExtraLarge = "header-module--hide-for-extra-large--JMfmb";
export var showForMobileOnly = "header-module--show-for-mobile-only--vFa6q";
export var showForTabletOnly = "header-module--show-for-tablet-only--9004v";
export var showForLaptopOnly = "header-module--show-for-laptop-only--VNlGF";
export var showForDesktopOnly = "header-module--show-for-desktop-only--IerES";
export var showForExtraLargeOnly = "header-module--show-for-extra-large-only--4dkOA";
export var fullPageContainer = "header-module--full-page-container--sQg8j";
export var container = "header-module--container--O5DHA";
export var isOpen = "header-module--isOpen--J9nQh";
export var logo = "header-module--logo--WAoX3";
export var hamburger = "header-module--hamburger--uEOZj";
export var close = "header-module--close--TMDq4";
export var hide = "header-module--hide--7Cm1O";
export var light = "header-module--light--SFOJ1";
export var menuButtonContainer = "header-module--menuButtonContainer--nM5y-";
export var menuButton = "header-module--menuButton--cRZoi";
export var modalCloseButton = "header-module--modalCloseButton--+IIcB";