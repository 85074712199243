import React, { useState, useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import FadeIn from "../components/Animated/FadeIn"
import LoadingScreen from "./LoadingScreen"
import { ModalProvider } from './Portals/Modal'

const Layout = ({ path, children }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      window.siteLoaded = true;
    }, 2000)
  }, [])

  return (
    <div className="body">
      <ModalProvider>
        <AnimatePresence>
          <LoadingScreen key="loading" loading={loading} />
          <Header key="header" pathname={path} />
          <FadeIn key="outer">{children}</FadeIn>
          <Footer key="footer" />
        </AnimatePresence>
      </ModalProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
