import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react"
import * as styles from "./header.module.scss"
import classNames from "classnames"
import { navigate } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import Menu from "../menu"
import HSLogo from "../HSLogo"
import { ModalContext } from "../Portals/Modal"
import CloseIcon from "../../assets/svgs/close-icon.svg"

import useDocumentScrollThrottled from "../../hooks/useDocumentScrollThrottled"

const duration = 0.5
const MINIMUM_SCROLL = 80;

const variants = {
  initial: {
    y: "-100%",
  },
  enter: {
    y: "0%",
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    background: "green",
    transition: { duration: duration },
  },
}

const Header = ({ pathname }) => {
  const headerRef = useRef()
  const {
    open: modalOpen,
    handleBodyScrolled,
    handleClose,
  } = useContext(ModalContext)
  const [hideHeader, setHideHeader] = useState(false)
  const [headerLightColor, setHeaderLightColor] = useState(
    pathname.indexOf("case-studies") > -1 ? "#fff" : "#000"
  )
  const [menuOpen, handleMenu] = useState(false)

  function headerVariants(headerLightColor) {
    return {
      background: {
        init: "rgba(255,255,255, 0)",
        scrolled: "rgba(255,255,255, 1)",
      },
      color: {
        init: headerLightColor,
        scrolled: "#000",
      },
      height: {
        init: "85px",
        scrolled: "65px",
      },
    }
  }
  

  const [HeaderBGColor, setHeaderBGColor] = useState(
    headerVariants(headerLightColor).background.init
  )
  const [HeaderHeight, setHeaderHeight] = useState(
    headerVariants(headerLightColor).height.init
  )
  const [HeaderTextColor, setHeaderTextColor] = useState(
    headerVariants(headerLightColor).color.init
  )
  const toggleMenu = () => handleMenu(!menuOpen)
  const handleLogoClick = () => navigate("/")

  const fadeUpVariants = {
    initial: {
      opacity: 0,
      y: 170,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        duration: 0.6,
        ease: [0.165, 0.84, 0.44, 1],
      },
    },
  }

  useDocumentScrollThrottled(({ previousScrollTop, currentScrollTop }) => {
    if (!currentScrollTop) {
      setHideHeader(false)
    }

    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled =
      Math.abs(previousScrollTop - currentScrollTop) > MINIMUM_SCROLL
    const { height, background, color } = headerVariants(headerLightColor)

    if (isMinimumScrolled && !modalOpen) {
      setHideHeader(isScrolledDown)
    }

    if (currentScrollTop > MINIMUM_SCROLL) {
      setHeaderBGColor(background.scrolled)
      setHeaderTextColor(color.scrolled)
      setHeaderHeight(height.scrolled)
      handleBodyScrolled(true)
    } else {
      setHeaderBGColor(background.init)
      setHeaderTextColor(
        window.location.pathname.indexOf("case-studies") > -1 ? "#fff" : "#000"
      )
      setHeaderHeight(height.init)
      handleBodyScrolled(false)
    }
  })

  useLayoutEffect(() => {
    setHeaderLightColor(pathname.indexOf("case-studies") > -1 ? "#fff" : "#000")
     setHeaderTextColor(
       window.location.pathname.indexOf("case-studies") > -1 ? "#fff" : "#000"
     )
  }, [pathname])

  useEffect(() => {
    if (modalOpen) {
      setHideHeader(false)
    }
  }, [modalOpen])

  useEffect(() => {
    const isIOS = () => {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    const activeClassName = isIOS() ? ['menu-active', 'ios'] : ['menu-active']

    if (menuOpen) {
      document.querySelector("body").classList.add(...activeClassName)
    } else {
      document.querySelector("body").classList.remove(...activeClassName)
    }
  }, [menuOpen])

  return (
    <AnimatePresence>
      <motion.header
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        ref={headerRef}
        className={classNames(styles.container, {
          [styles.light]: headerLightColor,
          [styles.hide]: hideHeader,
        })}
        style={{
          color: HeaderTextColor,
          height: HeaderHeight,
          backgroundColor: HeaderBGColor,
        }}
      >
        <motion.div
          variants={fadeUpVariants}
          initial="initial"
          onClick={handleLogoClick}
        >
          <HSLogo />
        </motion.div>
        <motion.div
          variants={fadeUpVariants}
          initial="initial"
          className={styles.menuButtonContainer}
        >
          {modalOpen ? (
            <CloseIcon
              className={styles.modalCloseButton}
              onClick={() => handleClose()}
            />
          ) : (
            <motion.div
              className={styles.menuButton}
              initial="visible"
              animate={menuOpen ? "hidden" : "visible"}
              variants={{
                visible: {
                  height: "14px",
                  width: "36px",
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
                hidden: {
                  height: 0,
                  width: "56px",
                  transition: {
                    duration: 0.3,
                  },
                },
              }}
            >
              <div
                className={styles.hamburger}
                onClick={toggleMenu}
                role="button-menu"
              >
                <span></span>
                <span></span>
              </div>
            </motion.div>
          )}
        </motion.div>

        <Menu handleMenu={toggleMenu} open={menuOpen} pathname={pathname} />
      </motion.header>
    </AnimatePresence>
  )
}

export default Header
