// extracted by mini-css-extract-plugin
export var hideForMobile = "LoadingScreen-module--hide-for-mobile--53ptx";
export var hideForTablet = "LoadingScreen-module--hide-for-tablet--ntT33";
export var hideForLaptop = "LoadingScreen-module--hide-for-laptop--6r5gY";
export var hideForDesktop = "LoadingScreen-module--hide-for-desktop---tYAQ";
export var hideForExtraLarge = "LoadingScreen-module--hide-for-extra-large--vJJnf";
export var showForMobileOnly = "LoadingScreen-module--show-for-mobile-only--K-l2k";
export var showForTabletOnly = "LoadingScreen-module--show-for-tablet-only--kROio";
export var showForLaptopOnly = "LoadingScreen-module--show-for-laptop-only--iPv0V";
export var showForDesktopOnly = "LoadingScreen-module--show-for-desktop-only--Jadzb";
export var showForExtraLargeOnly = "LoadingScreen-module--show-for-extra-large-only--KWtpt";
export var fullPageContainer = "LoadingScreen-module--full-page-container--zyclx";
export var container = "LoadingScreen-module--container--O-8Qn";
export var flagContainer = "LoadingScreen-module--flagContainer--SulVw";