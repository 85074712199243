// extracted by mini-css-extract-plugin
export var hideForMobile = "HSFlag-module--hide-for-mobile--aP2vy";
export var hideForTablet = "HSFlag-module--hide-for-tablet--SgDIu";
export var hideForLaptop = "HSFlag-module--hide-for-laptop--oIofo";
export var hideForDesktop = "HSFlag-module--hide-for-desktop--AdXNE";
export var hideForExtraLarge = "HSFlag-module--hide-for-extra-large--+fOTN";
export var showForMobileOnly = "HSFlag-module--show-for-mobile-only--HDymi";
export var showForTabletOnly = "HSFlag-module--show-for-tablet-only--no+GX";
export var showForLaptopOnly = "HSFlag-module--show-for-laptop-only--Sw4I5";
export var showForDesktopOnly = "HSFlag-module--show-for-desktop-only--I1oPN";
export var showForExtraLargeOnly = "HSFlag-module--show-for-extra-large-only--4qK5+";
export var fullPageContainer = "HSFlag-module--full-page-container--YznDG";
export var logo = "HSFlag-module--logo--D0do-";